/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useThemeProps } from '@mui/material';
import {
  default as MuiChip,
  ChipTypeMap as MuiChipTypeMap,
} from '@mui/material/Chip';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useIsAncestorDisabled } from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import type {} from './chipModuleAugmentations';
import { inProgressColor } from './style/colors';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChipOwnProps {}

export type ChipTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiChipTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiChipTypeMap,
  AdditionalProps & ChipOwnProps,
  RootComponent
>;

export type ChipProps<
  RootComponent extends React.ElementType = MuiChipTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<ChipTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const Chip = forwardRefToOverridableComponent<ChipTypeMap, ChipProps>(
  function Chip(inProps, forwardedRef) {
    const props = useThemeProps({ props: inProps, name: 'MuiChip' });
    const {
      color: colorProp = 'default',
      variant = 'filled',
      // Prevent props.children from being passed along to MUI Chip since it's not supported.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      children,
      disabled: disabledProp,
      ...rest
    } = props;

    let color: typeof colorProp = colorProp;
    if (colorProp === 'inProgress') {
      color = inProgressColor; // See inProgressColor for more info
    }

    const ancestorDisabled = useIsAncestorDisabled();
    const disabled = disabledProp ?? ancestorDisabled ?? false;
    return (
      <MuiChip
        disabled={disabled}
        color={color}
        variant={variant}
        ref={forwardedRef}
        {...rest}
      />
    );
  }
);

export default Chip;
