/* eslint-disable deprecation/deprecation */
import {
  createTheme as createThemeWithoutVars,
  PaletteOptions,
  Theme,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import createTypographyOptions from 'src/styles/createTypographyOptions';
import { defaultSpacingScalingFactor } from 'src/styles/themes/constants';
import sharedComponentDefaults from 'src/styles/themes/sharedComponentDefaults';
import wsdBlue from 'src/wsd/styles/colors/blue';
import wsdGreen from 'src/wsd/styles/colors/green';
import wsdGrey from 'src/wsd/styles/colors/grey';
import wsdOrange from 'src/wsd/styles/colors/orange';
import wsdPurple from 'src/wsd/styles/colors/purple';
import wsdRed from 'src/wsd/styles/colors/red';
import wsdComponents, {
  wsdComponentPaletteStubs,
} from 'src/wsd/styles/theme/wsdComponents';
import getWsdTypography, {
  wsdFontFamily,
} from 'src/wsd/styles/theme/wsdTypography';

/**
 * The legacy "Workiva" application theme.
 *
 * This theme is designed to match Web Skin Dart styling as closely as possible.
 *
 * It will be deprecated in a future release as a new `defaultTheme` is
 * created to align with the latest design system iterations.
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const wkTheme = createThemeWithoutVars({
  isLegacyWsdTheme: true,
  components: deepmerge(sharedComponentDefaults, wsdComponents),
  palette: getWsdPalette(),
  typography: (palette) =>
    createTypographyOptions(
      palette,
      {
        fontFamily: wsdFontFamily,
        ...getWsdTypography(palette),
      },
      defaultSpacingScalingFactor,
      true
    ),
  spacingScalingFactor: defaultSpacingScalingFactor,
  spacing: defaultSpacingScalingFactor,
  shape: {
    borderRadius: 4,
  },
}) as Omit<Theme, 'isLegacyWsdTheme'> & { isLegacyWsdTheme: true };

function getWsdPalette(): PaletteOptions {
  const palette: PaletteOptions = {
    primary: {
      main: wsdGreen.main,
      dark: wsdGreen.dark,
      light: wsdGreen[600],
      highlight: wsdGreen.highlight,
      onHighlight: wsdGreen.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD primary colors.
      contrastText: '#fff',
    },
    secondary: {
      main: wsdBlue.main,
      dark: wsdBlue.dark,
      light: wsdBlue[600],
      highlight: wsdBlue.highlight,
      onHighlight: wsdBlue.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD secondary colors.
      contrastText: '#fff',
    },
    error: {
      main: wsdRed.main,
      dark: wsdRed.dark,
      light: wsdRed[600],
      highlight: wsdRed.highlight,
      onHighlight: wsdRed.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD secondary colors.
      contrastText: '#fff',
    },
    warning: {
      main: wsdOrange.main,
      dark: wsdOrange.dark,
      light: wsdOrange[600],
      highlight: wsdOrange.highlight,
      onHighlight: wsdOrange.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD secondary colors.
      contrastText: '#fff',
    },
    info: {
      main: wsdBlue.main,
      dark: wsdBlue.dark,
      light: wsdBlue[600],
      highlight: wsdBlue.highlight,
      onHighlight: wsdBlue.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD secondary colors.
      contrastText: '#fff',
    },
    success: {
      main: wsdGreen.main,
      dark: wsdGreen.dark,
      light: wsdGreen[600],
      highlight: wsdGreen.highlight,
      onHighlight: wsdGreen.onHighlight,
      // This is hard-coded as white to ensure visual parity with WSD secondary colors.
      contrastText: '#fff',
    },
    blue: wsdBlue,
    green: wsdGreen,
    orange: wsdOrange,
    purple: wsdPurple,
    red: wsdRed,
    grey: wsdGrey,
    /**
     * We need to include these stubs by default when creating a theme
     * so that our custom `Button` / `ButtonGroup` components don't choke.
     */
    ...wsdComponentPaletteStubs,
  };

  return {
    ...palette,
    divider: wsdGrey[200],
    text: {
      primary: wsdGrey[800],
      secondary: wsdGrey[600],
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      activatedOpacity: 0.12,
      active: 'rgba(0, 0, 0, 0.54)',
      mutedOpacity: 0.54, // To replace web-skin's text-muted CSS class
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.45,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      focusOutlineColor: wsdBlue[600],
    },
  };
}

export default wkTheme;
