// Main exports of the Unify Browser global
export * as react_virtual from '@tanstack/react-virtual';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from 'src';
export * from 'src/components.dart';
export { createMaterialSymbolsIcon } from 'src/components/MaterialSymbolsIcon/createMaterialSymbolsIcon';
export { default as buttonClasses } from 'src/wsd/components/Button/buttonClasses';
export {
  DisabledAncestorProvider,
  useIsAncestorDisabled,
} from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
export * from 'src/localization/localizedDayjs';

// Keep WsdAlert in the bundle to support the deprecated WsdAlert Dart component.
export {
  /* eslint-disable-next-line deprecation/deprecation */
  WsdAlert,
  /* eslint-disable-next-line deprecation/deprecation */
  type WsdAlertProps,
} from 'src/wsd/components/WsdAlert/WsdAlert';

// Re-export Unify-prefixed components as their non-prefixed names. These non-prefixed names are no
// longer used anywhere since react_material_ui was deleted, so we can change them over to the Unify
// versions. Once these have baked in the JS bundle for a while, we can remove the Unify-prefixed
// un_wsd_components.tsx and simply export the non-prefixed components from components.dart.ts like
// all other components.
export {
  UnifyAlert as Alert,
  type UnifyAlertProps as AlertProps,
  UnifyBadge as Badge,
  type UnifyBadgeProps as BadgeProps,
  UnifyLinearProgress as LinearProgress,
  type UnifyLinearProgressProps as LinearProgressProps,
} from 'src/un_wsd_components';

/**
 * Loads the ReactMaterialUI DateRangePicker js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DateRangePicker} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DateRangePicker
 * bundle earlier in the background before {@link DateRangePicker} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DateRangePicker}.
 */
export async function loadDatePickers(): Promise<
  typeof import('./browser.date_pickers')
> {
  return await import('./browser.date_pickers');
}

/**
 * Loads the ReactMaterialUI DataGrid js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPro} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGrid
 * bundle earlier in the background before {@link DataGridPro} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPro}.
 */
export async function loadDataGrid(): Promise<void> {
  await import('./browser.data_grid_v6');
}

/**
 * Loads the ReactMaterialUI DataGridPro js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPro} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGridPro
 * bundle earlier in the background before {@link DataGridPro} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPro}.
 */
export async function loadDataGridPro(): Promise<void> {
  await import('./browser.data_grid_pro_v7');
}

/**
 * Loads the ReactMaterialUI DataGridPremium js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPremium} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGridPremium
 * bundle earlier in the background before {@link DataGridPremium} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPremium}.
 */
export async function loadDataGridPremium(): Promise<void> {
  await import('./browser.data_grid_premium');
}

// We don't want to generate this, so export it from this file instead of from components.dart.ts
export { DataGridProLazy as DataGridPro } from 'src/components/DataGridPro/DataGridProLazyV6';
